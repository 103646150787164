import React from "react";
import {
  TabUnstyled,
  TabsListUnstyled,
  buttonUnstyledClasses,
  tabUnstyledClasses,
} from "@mui/base";
import { styled } from "@mui/system";

const Tabs = ({ tabsList, handleTab }) => {
  const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${"#FF6700"};
  cursor: pointer;
  font-size: 16px;
  height: 34px;
  font-weight: bold;
  // background-color: transparent;
  background-color: ${"#FFF8F1"};
  width: fit-content;
  margin: 0.313vw 0.3vw;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;


  &:hover {
    background-color: ${"#FFE7CE"};
  }

  &:focus {
    color: #ffffff;
    border-radius: 6px;
    outline: 2px solid ${"#333333"};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${"#FF6700"};
    color: ${"#FFFFFF"};
    // margin: 0.313vw 0.5vw;
    height: 34px;
    display: flex;
    align-items: center;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

  const TabsList = styled(TabsListUnstyled)`
  width: fit-content;
  background-color: ${"#FFF8F1"};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  padding:5px 0px;
`;

  return (
    <div>
      <TabsList>
        {tabsList.map((tab, index) => (
          <Tab
            onClick={() => {
              handleTab(index);
            }}
          >
            {tab}
          </Tab>
        ))}
      </TabsList>
    </div>
  );
};

export default Tabs;
