/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Routes, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { reactLocalStorage } from "reactjs-localstorage";

import routes from "routes.js";
import "./index.css";
import api from "../services/api";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const [token, setToken] = React.useState(
    reactLocalStorage.getObject("token")
  );
  const logout = async () => {
    await reactLocalStorage.remove("userData");
    await reactLocalStorage.remove("token");
    // history.push("/");
  };

  // const isTokenExpired = async (token) => {
  //   const isExpired = await api.checkTokenExpiry(token);
  //   console.log({ isExpired });
  //   if (isExpired?.data?.data?.tokenExpired) {
  //     logout();
  //   }
  // };

  // React.useEffect(() => {
  //   const checkTokenExpiration = async () => {
  //     await isTokenExpired(token);
  //     // console.log({token, a: await isTokenExpired(token)});
  //     // if (token && await isTokenExpired(token)) {
  //     //   logout(); // Log out the user if the token has expired
  //     // }
  //   };

  //   const timer = setInterval(checkTokenExpiration, 15 * 60 * 1000); // Check token expiration every second

  //   return () => {
  //     clearInterval(timer); // Clean up the timer when the component unmounts
  //   };
  // }, [token]);

  const isEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  };
  const isAuthenticated = () => {
    const user = reactLocalStorage.getObject("userData");

    const isLoggedIn = !isEmpty(user);
    return isLoggedIn;
  };
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <div
      className="mainContainerBox"
      style={{ backgroundColor: "#FFF8F0", height: "100%", minHeight: "100vh" }}
    >
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/logo.svg").default,
          imgAlt: "...",
        }}
        width={"200px"}
      />
      <div className="main-content dashboard-cont" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />

        <Route>
          {getRoutes(routes)}
          {!isAuthenticated() && <Redirect from="*" to="/admin/index" />}
        </Route>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </div>
  );
};

export default Admin;
