import React from "react";
import { Input, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";
import styles from "./styles.module.scss";

const InputField = ({
  min,
  max,
  value,
  label,
  prefix,
  placeholder,
  onChange,
  disabled = false,
  type = "text",
}) => {
  return (
    <>
      <label className={styles.label}>{label}</label>
      <InputGroup>
        {prefix && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{prefix}</InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          prefix="$"
          min={min}
          type={type}
          maxLength={max}
          disabled={disabled}
          defaultValue={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </InputGroup>
    </>
  );
};

export default InputField;
