import React from "react";
import styles from "./styles.module.scss";

const StatusTag = ({ type, title, isJobTag = false }) => {
  return (
    <div className={`${styles.tagContainer} ${isJobTag ? styles.jobTag : ""}`}>
      <span className={`${styles[type]}`}>{title}</span>
    </div>
  );
};

export default StatusTag;
