import axios from "axios";
import { toast } from "react-toastify";

const handleError = (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location.href = "#/auth/login";
    return toast.error("Session Expired!");
  }
};

const getHeaders = () => {
  const token = localStorage.getItem("token");
  const withAuthHeaders = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return withAuthHeaders;
};
export const getWithAuth = (endpoint) => {
  return axios
    .get(`${endpoint}`, getHeaders())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log({ error });
      handleError(error);
      throw error;
    });
};
export const deleteWithAuth = (endpoint) => {
  return axios
    .delete(`${endpoint}`, getHeaders())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error;
    });
};
export const postWithAuth = (endpoint, body) => {
  if (body !== null && body !== undefined) {
    return axios
      .post(`${endpoint}`, body, getHeaders())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        handleError(error);
        throw error;
      });
  } else {
    return axios
      .post(`${endpoint}`, getHeaders())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }
};
export const patchWithAuth = (endpoint, body) => {
  console.log({ endpoint, body, a: getHeaders() });
  if (body !== null && body !== undefined) {
    return axios
      .patch(`${endpoint}`, body, getHeaders())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        handleError(error);
        throw error;
      });
  } else {
    return axios
      .patch(`${endpoint}`, {}, getHeaders())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        handleError(error);
        throw error;
      });
  }
};
