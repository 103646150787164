//New Dev URL
//Dev
export const BASE_URL = "https://dev.megamindserver.com";

//Production URL
//const BASE_URL = "https://megamindserver.com";

const Path = {
  //USERS
  LOGIN: `${BASE_URL}/api/v1/users/login`,
  GET_ALL_USERS: `${BASE_URL}/api/v1/users`,
  GET_ALL_USERS_BY_ID: `${BASE_URL}/api/v1/users`,
  GET_ALL_JOBS: `${BASE_URL}/api/v1/jobPosting`,
  DELETE_JOB_BY_ID: `${BASE_URL}/api/v1/jobPosting`,
  ACTIVITY_LOG: `${BASE_URL}/api/v1/activitylog`,
  GET_ALL_COURSES: `${BASE_URL}/api/v1/courses`,
  GET_ALL_PUSHNOTIFICATION: `${BASE_URL}/api/v1/fireNotification`,
  GET_USER_SCHEDULE: `${BASE_URL}/api/v1/jobPosting/user/schedule`,
  QUALIFICATION: `${BASE_URL}/api/v1/qualification`,
  GET_BANK_ACCOUNT_DETAILS: `${BASE_URL}/api/v1/bankDetail`,
  RESCHEDULE: `${BASE_URL}/api/v1/jobPosting/reschedule`,
  CLASS_RESCHEDULE: `${BASE_URL}/api/v1/classReschedule`,
  CLASS_DELETE_REQUEST: `${BASE_URL}/api/v1/classDeleteRequest`,
  GET_JOB_BY_ID: `${BASE_URL}/api/v1/jobPosting`,
  REQUESTS: `${BASE_URL}/api/v1/users/requests`,
  GET_ALL_JOB_REQUESTS: `${BASE_URL}/api/v1/jobRequest`,
  JOB_CANCEL_REQUESTS: `${BASE_URL}/api/v1/jobTermination`,
  BILLING: `${BASE_URL}/api/v1/billing`,
  BANK_BILLING_REQUEST: `${BASE_URL}/api/v1/billing/adminBankBilling`,
  GET_BANK_BILLING_REQUEST: `${BASE_URL}/api/v1/billing/bankPaymentRequest`,
  BANK_BILLING_REQUEST_REJECTED: `${BASE_URL}/api/v1/billing/adminBankBillingRejected`,
  SALARIES: `${BASE_URL}/api/v1/billing/tutor/salaries`,
  TUITION_STATS: `${BASE_URL}/api/v1/billing/tuition/stats`,
  BULK_CHARGE: `${BASE_URL}/api/v1/chargeAllBills`,
  DASHBOARD_STATS: `${BASE_URL}/api/v1/billing/dashboard/stats`,
  VOUCHER: `${BASE_URL}/api/v1/voucherDetail`,
  GET_REVIEW: `${BASE_URL}/api/v1/review`,
  USER_NOTIFICATION: `${BASE_URL}/api/v1/fireNotification`,
  VERIFY_EMAIL: `${BASE_URL}/api/v1/emailVerification`,
  CHECK_EXPIRY: `${BASE_URL}/api/v1/users/check-token-expiry`,
  JOB_TERMINATION_REQUEST: `${BASE_URL}/api/v1/jobTermination/request`,
  JOB_TERMINATION_ACCEPT: `${BASE_URL}/api/v1/jobTermination/accept`,
  JOB_TERMINATION_REJECT: `${BASE_URL}/api/v1/jobTermination/reject`,
};

export { Path };
