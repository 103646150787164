import { Grid } from "@mui/material";
import InputField from "components/InputField";
import RadioButtonGroup from "components/RadioField";
import React from "react";
import { Button, Modal } from "reactstrap";

const CreateVoucherModal = ({
  err,
  state,
  isOpen = false,
  setOpen,
  setPrice,
  setMinSpend,
  publishTo,
  setState,
  setDate,
  allTutees,
  onSelect,
  postVoucher,
  selectedTutee,
  setVoucherName,
  selectedVoucher,
  setSelectedVoucher,
  handleVoucherStatus,
  setShortDescription,
}) => {
  const setMinDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    return formattedDate;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => {
        setOpen(!isOpen);

        if (state.isEditMode) {
          // setSelectedVoucher({});
          setState((prev) => ({
            ...prev,
            isEditMode: false,
          }));
        }
      }}
    >
      <div style={{ padding: "25px" }}>
        <div style={{ marginTop: "35px", textAlign: "center" }}>
          <h1>{`${state.isEditMode ? "Update" : "Create"} Voucher`}</h1>
          <p style={{ color: "#9A9A9A" }}>
            {" "}
            {`${state.isEditMode ? "" : "Create and publish a new voucher"}`}
          </p>
        </div>
        <div className="voucher-modal-data">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                max={24}
                label={
                  <div className="shortDescription">
                    <div>Voucher Title</div>
                    <div className="shortDescCount">Max 24 characters</div>
                  </div>
                }
                type="text"
                // label="Voucher Title"
                value={selectedVoucher["title"]}
                placeholder="Enter voucher title"
                onChange={(e) => setVoucherName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                type="textarea"
                max={60}
                label={
                  <div className="shortDescription">
                    <div>Short Description</div>
                    <div className="shortDescCount">Max 60 characters</div>
                  </div>
                }
                value={selectedVoucher["shortDescription"]}
                placeholder="Enter short description"
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                prefix="$"
                label="Price"
                type="number"
                placeholder="Price"
                value={selectedVoucher["price"]}
                disabled={selectedVoucher["price"]}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                prefix="$"
                type="number"
                label="Min Spend Amount"
                placeholder="Enter Amount"
                value={selectedVoucher["minSpend"]}
                onChange={(e) => setMinSpend(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                label="Expiry Date"
                type="date"
                min={setMinDate()}
                value={selectedVoucher["expiryDate"]}
                onChange={(e) => setDate(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ paddingTop: "10px" }}>
          <RadioButtonGroup
            selectedOption={
              selectedVoucher["promStatus"] ?? state.voucherStatus
            }
            onChange={handleVoucherStatus}
            options={[
              { label: "Active", value: "Active" },
              { label: "In Active", value: "InActive" },
            ]}
          />
        </div>

        {err && (
          <p style={{ textAlign: "center", color: "red", marginTop: "20px" }}>
            {err}
          </p>
        )}

        <div className="modal-footer">
          <Button
            disabled={state.isLoading}
            style={{ width: "100%", height: "40px", paddingTop: "8px" }}
            className="modalFooter publishVoucher"
            onClick={() => postVoucher()}
          >
            {`${state.isEditMode ? "Update Voucher" : "Publish Voucher"}`}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateVoucherModal;
