import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Row,
  UncontrolledTooltip,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Col,
} from "reactstrap";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import "./index.css";

const localizer = momentLocalizer(moment);
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const ProfileCard = ({ user, editUser, getUsersById, totalSpended = 0 }) => {
  return (
    <div className="card-profile shadow ProfileMainCard">
      <div style={{ padding: "1.563vw 1.563vw 0vw" }}>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%", padding: "0px 0px 1.458vw" }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: -10,
              backgroundColor: "#ff6700",
              color: "#FFFFFF",
              height: "1.458vw",
              width: "4.010vw",
              fontSize: "0.729vw",
              fontWeight: 400,
              boxShadow:
                "0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1)",
              border: "0px solid #FF3A3A",
              borderRadius: "6px",
            }}
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            {/* Message */}
          </button>
          <div className="card-profile-image">
            {user?.user?.photoUrl ? (
              <img
                src={user?.user?.photoUrl}
                style={{
                  width: "9.375vw",
                  height: "9.479vw",
                  borderRadius: "50%",
                  position: "absolute",
                  right: "-4.479vw",
                  bottom: "-5vw",
                }}
              />
            ) : (
              <Avatar
                style={{
                  width: "9.375vw",
                  height: "9.479vw",
                  borderRadius: "50%",
                  backgroundColor: "#ced4da",
                  position: "absolute",
                  right: "-4.479vw",
                  bottom: "-3.333vw",
                }}
                alt={user?.user?.photoUrl}
                src="/"
              />
            )}
          </div>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FFFFFF",
              color: "#FF3A3A",
              height: "1.458vw",
              width: "5.2vw",
              fontSize: "0.629vw",
              fontWeight: 400,
              borderRadius: "6px",
              border: "0.5px solid #FF3A3A",
            }}
            className="float-right"
            color="default"
            href="#pablo"
            onClick={async () => {
              editUser(user?.user?._id, {
                user: {
                  status:
                    user?.user?.status === "Active" ? "InActive" : "Active",
                },
              }).then(() => getUsersById());
            }}
          >
            {user?.user?.status === "Active" ? "Block User" : "Unblock User"}
          </button>
        </div>
      </div>

      <div style={{ padding: "1.302vw 1.563vw 1.146vw" }}>
        <div className="ProfileInfoCard">
          <div className="ProfileUserInfoCard">
            <div className="ProfileInfoCardValueMLeft">
              <div className="heading ProfileCard1845">
                {user?.jobDetails?.ongoing}
              </div>
              <div className="description ProfileCard149A">Ongoing Tution</div>
            </div>
            <div className="ProfileInfoCardValueNoM">
              <div className="heading ProfileCard1845">
                {user?.jobDetails?.total}
              </div>
              <div className="description ProfileCard149A">Total Tution</div>
            </div>
            <div className="ProfileInfoCardValueMRight">
              <div className="heading ProfileCard1845">${totalSpended}</div>
              <div className="description ProfileCard149A">Total Spent</div>
            </div>
          </div>
        </div>
        <div>
          <div className="ProfileCardMT30">
            <h3
              className="border-0 ProfileCard2045"
              style={{
                marginBottom: "4px",
                textAlign: "left",
                fontWeight: 600,
              }}
            >
              {user?.user?.name ?? "N/A" + " "}

              <span
                className="font-weight-light ProfileCard169A"
                style={{ textAlign: "left", fontWeight: 600 }}
              >
                {" "}
                - TUTEE
              </span>
            </h3>
            <div style={{ color: "#9A9A9A" }} className="h4 font-weight-300">
              <div
                style={{
                  fontSize: "0.833vw",
                  color: "#9A9A9A",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div className="tuteeIdBlock">ID: {user.user?.memberId}</div>{" "}
                {user?.user?.gender ? user?.user?.gender + ", " : null}
                {user?.user?.dob ? getAge(user?.user?.dob) : ""}
              </div>
            </div>
          </div>

          <hr className="ProfileCardHR"></hr>

          <div className="ProfileColor9A">
            <i
              style={{ fontSize: "0.833vw", color: "#ff6700" }}
              className="fas fa-envelope"
            ></i>{" "}
            <span style={{ fontSize: "0.729vw" }} className="description">
              {user?.user?.email}
            </span>
          </div>

          <div style={{ marginTop: "0.260vw" }}>
            <i
              style={{ color: "#ff6700", fontSize: "0.833vw" }}
              className="fas fa-phone-alt"
            ></i>{" "}
            <span style={{ fontSize: "0.729vw" }} className="ProfileColor9A">
              {" "}
              {user?.user?.mobilePhone ? user?.user?.mobilePhone : "--- -----"}
            </span>{" "}
          </div>

          <div style={{ marginTop: "0.260vw" }}>
            <i
              style={{ color: "#ff6700", fontSize: "0.833vw" }}
              className="fas fa-map-marker-alt"
            ></i>{" "}
            <span style={{ fontSize: "0.729vw" }} className="ProfileColor9A">
              {user?.user?.addressUnit
                ? user?.user?.addressUnit + " " + user?.user?.addressLine
                : " Not Available"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
