import "./index.css";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { JobStatusEnum } from "helpers/common-helper";
// import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  Button,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

import api from "../../services/api";
import { createEventId } from "./event-utils";
import React, { useState, useEffect } from "react";
import RescheduleClassModal from "views/pages/RescheduleClassModal";
import { isDatePassed } from "helpers/common-helper";
import { getCurrentUserInfo } from "helpers/common-helper";
import { removeEmptyValues } from "helpers/common-helper";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
// import PendingIcon from "@mui/icons-material/Pending";

import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import RepeatRoundedIcon from "@mui/icons-material/RepeatRounded";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import StatusTag from "components/StatusTag";

const Schedule = (props) => {
  const [modal, setModal] = useState(false);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [allEvents, setAllEvents] = useState(props.schedule);
  const [oldDate, setOldDate] = useState("");
  const [newDate, setNewDate] = useState("");
  const [id, setId] = useState("");
  const [newData, setNewData] = useState("");
  const [jobId, setJobId] = useState("");
  const [logginUser, setLogginUser] = useState({});
  const [jobPostColor, setJobPostColor] = useState("");
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    rescheduleModalIsOpen: false,
  });

  const handleRescheduleModal = () => {
    setState((prev) => ({
      ...prev,
      rescheduleModalIsOpen: !prev.rescheduleModalIsOpen,
    }));
  };

  useEffect(() => {
    const fetchUser = async () => {
      const user = await reactLocalStorage.getObject("userData");
      setLogginUser(user);
    };

    fetchUser();
  }, []);

  const convertDate = (date) => {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    return `${yyyy}-${mm.padStart(2, "0")}-${dd.padStart(2, "0")}`;
  };

  const convertDateFormat = (date) => {
    var today = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${today.getDate()} ${
      monthNames[today.getMonth()]
    }, ${today.getFullYear()}`;
  };

  const getCurrentDateTime = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
  };

  const reschedule = async (data, newDate, title, color, oldDate, jobId) => {
    const newData = {
      ...data,
      date: newDate,
      rescheduleRef: data._id,
      title,
      color,
    };
    delete newData._id;
    delete newData.createdAt;
    delete newData.updatedAt;

    setNewDate(newDate);
    setOldDate(oldDate);
    setId(data._id);
    setNewData(newData);
    setJobId(jobId);
    setIsOpen(true);
  };

  const handleReschedule = async () => {
    await api.reschedule(id, newData);
    const nod = convertDateFormat(oldDate);
    const nnd = convertDateFormat(newDate);
    const data = {
      adminId: logginUser._id,
      jobId: jobId,
      message: `${nod} to ${nnd}`,
      activityType: "Reschedule",
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
    setIsOpen(false);
    props.closeCalendar();
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleEventClick = ({ event }) => {
    setEvent(event);
    toggleModal();
  };

  // const handleWeekendsToggle = () => {
  //   setWeekendsVisible(!weekendsVisible);
  // };

  const handleDateSelect = (selectInfo) => {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(), // Assuming createEventId() is defined elsewhere
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  // const handleEvents = (events) => {
  //   setCurrentEvents(events);
  // };

  const updateSchedule = async (updatedSchedule, event) => {
    const { getUserSchedule } = props;

    const payload = {
      jobId: event?.jobId,
      newDate: updatedSchedule?.newDate ?? event.oldDate,
      newStartTime: updatedSchedule?.newTime ?? event.time?.split("-")[0],
      senderId: getCurrentUserInfo()._id,
      receiverId: event?.tuteeId,
      classId: event._id,
      isAdmin: true,
    };

    setLoading(true);

    try {
      await api.classReschedule(removeEmptyValues(payload));
      toast.success("Schedule Updated Successfully!");
      handleRescheduleModal();
      setLoading(false);
      getUserSchedule();
    } catch (error) {
      const { response } = error;

      toast.error(response?.data?.message ?? "Some error occured!");
      setLoading(false);
    }
  };

  const handleClassDeleteRequest = async () => {
    const { getUserSchedule } = props;

    const eventInfo = event?.extendedProps;

    const payload = {
      jobId: eventInfo?.jobId,
      senderId: getCurrentUserInfo()?._id,
      receiverId: eventInfo?.tuteeId,
      classId: eventInfo._id,
      isAdmin: true,
    };

    setLoading(true);

    try {
      await api.classDeleteRequest(removeEmptyValues(payload));

      if (eventInfo?.isPrePaid) {
        toast.success(
          "The Class has been Cancelled and new voucher has been created!"
        );
      } else {
        toast.success("The Class has been Cancelled");
      }
      toggleModal();
      setLoading(false);
      getUserSchedule();
    } catch (error) {
      const { response } = error;
      toast.error(response?.data?.message ?? "Some error occured!");
      setLoading(false);
    }
  };

  return (
    <CardBody>
      <div style={{ height: "100%" }}>
        <FullCalendar
          height={"100%"}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "",
          }}
          initialView="dayGridMonth"
          events={props.schedule}
          editable={props.editable}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          select={handleDateSelect}
          eventChange={(e) => {
            reschedule(
              e.event._def.extendedProps,
              convertDate(new Date(e.event._instance.range.start)),
              e.event._def.title,
              e.event._def.ui.backgroundColor,
              convertDate(new Date(e.oldEvent._instance.range.start)),
              e.oldEvent._def.extendedProps.jobId
            );
          }}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
        />
      </div>
      {modal && !isEmpty(event) && (
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalHeader toggle={toggleModal}>
            <div className="classDetailHeader">
              <div>
                <img src="/class-rescheule.svg" alt="reschedule-icon" />
              </div>

              <div>
                <h1 className="mb-0">Class Details</h1>

                <h3 className="classJobTitleHeading">
                  {`${event?.extendedProps?.courseName} - ${event?.extendedProps?.courseLvl} `}{" "}
                  <StatusTag
                    isJobTag={true}
                    type={JobStatusEnum(
                      event?.extendedProps?.jobStatus
                    )?.title?.toLowerCase()}
                    title={JobStatusEnum(event?.extendedProps?.jobStatus).title}
                  />
                </h3>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="p-0">
            <EventInfo event={event} />
          </ModalBody>

          <ModalFooter>
            {event?.extendedProps?.status !== "Cancelled" &&
              event?.extendedProps?.jobStatus !== 1 && (
                <>
                  <Button
                    color="secondary"
                    // className="rescheduleBtn"
                    style={{
                      backgroundColor: "#fff",
                      color: "#ff8a3c",
                      fontWeight: "bold",
                      borderColor: "#ff8a3c",
                    }}
                    onClick={handleClassDeleteRequest}
                  >
                    {`Delete `}
                    {loading && (
                      <Spinner
                        style={{
                          width: 16,
                          height: 16,
                          marginLeft: "5px",
                        }}
                      />
                    )}
                  </Button>

                  {!isDatePassed(
                    event?.extendedProps?.newDate ??
                      event?.extendedProps?.oldDate
                  ) && (
                    <Button
                      color="secondary"
                      className="rescheduleBtn"
                      onClick={() => {
                        toggleModal();
                        handleRescheduleModal();
                      }}
                    >
                      Reschedule
                    </Button>
                  )}
                </>
              )}
          </ModalFooter>
        </Modal>
      )}

      {state.rescheduleModalIsOpen && (
        <RescheduleClassModal
          loading={loading}
          event={event?.extendedProps}
          updateSchedule={updateSchedule}
          isOpen={state.rescheduleModalIsOpen}
          handleRescheduleModal={handleRescheduleModal}
        />
      )}
    </CardBody>
  );
};

export default Schedule;

function renderEventContent(eventInfo) {
  const eventDetails = eventInfo?.event?.extendedProps;

  // RepeatRoundedIcon
  return (
    <ul
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px 0px 0px 0px",
        width: "100%",
        padding: "0px 5px",
        opacity: eventDetails?.status === "Cancelled" ? 0.6 : 1,

        // height: "30px",
      }}
    >
      <b>{eventDetails.time}</b>

      {showIconBasedOnCondition(eventDetails)}
    </ul>
  );
}

const showIconBasedOnCondition = (eventDetails) => {
  if (eventDetails?.jobStatus === 1) {
    return <HourglassTopRoundedIcon fontSize="small" />;
  } else if (eventDetails?.status === "Cancelled") {
    return <BlockOutlinedIcon fontSize="small" />;
  } else if (eventDetails?.reschedule)
    return <RepeatRoundedIcon fontSize="small" />;
};

const EventInfo = ({ event }) => {
  const getDateFormate = (dateObject) => {
    const day = dateObject.getDate();
    const monthIndex = dateObject.getMonth();
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();

    // Month names array (you can customize it according to your needs)
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Format the date string
    const fullDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return fullDate;
  };

  const eventInfo = event?.extendedProps;

  return (
    <div className="classScheduleInfo">
      <div className="classStatusesList">
        {eventInfo?.isPrePaid && <StatusTag type="prepaid" title="Prepaid" />}
        {!eventInfo?.isPrePaid && (
          <StatusTag type="postpaid" title="Postpaid" />
        )}
        {eventInfo.reschedule && (
          <StatusTag type="rescheduled" title="Rescheduled" />
        )}

        {eventInfo.status === "Cancelled" && (
          <StatusTag type="cancelled" title="Cancelled" />
        )}
      </div>

      {eventInfo.reschedule ? (
        <>
          <div
            className="d-flex pt-4 w-100"
            // style={{
            //   width: "100%",
            // }}
          >
            <ScheduleInfo
              title="Date"
              cutThrough={true}
              value={getDateFormate(new Date(eventInfo.oldDate))}
            />

            <div
              style={{
                marginTop: "15px",
                width: "15%",
              }}
            >
              <i
                style={{ color: "#9A9A9A" }}
                className="fas fa-arrow-right"
              ></i>
            </div>

            <ScheduleInfo
              title="New Date"
              reschedule={eventInfo.reschedule}
              value={getDateFormate(new Date(eventInfo.newDate))}
            />
          </div>

          <div
            className="d-flex pt-4"
            style={{
              width: "100%",
            }}
          >
            <ScheduleInfo
              title="Time"
              cutThrough={true}
              value={eventInfo?.time}
            />

            <div
              style={{
                marginTop: "15px",
                width: "15%",
              }}
            >
              <i
                style={{ color: "#9A9A9A" }}
                className="fas fa-arrow-right"
              ></i>
            </div>

            <ScheduleInfo
              title="New Time"
              reschedule={eventInfo.reschedule}
              value={eventInfo?.newTime}
            />
          </div>
        </>
      ) : (
        <>
          <div className="pt-2">
            <ScheduleInfo
              title="Date"
              value={getDateFormate(new Date(event.start))}
            />
          </div>

          <div className="pt-3">
            <ScheduleInfo title="Time" value={eventInfo?.time} />
          </div>
        </>
      )}
    </div>
  );
};

const ScheduleInfo = ({
  title,
  value,
  reschedule = false,
  cutThrough = false,
}) => {
  return (
    <div
      style={{
        width: "30%",
      }}
    >
      <div className={`classScheduleTitle`}>{title}</div>

      <div
        className={`classScheduleValue ${
          reschedule ? "IsRescheduleClassNewInfo" : ""
        } ${cutThrough ? "IsRescheduleClass" : ""}`}
      >
        {value}
      </div>
    </div>
  );
};
