import "./index.css";

import { TabsUnstyled } from "@mui/base";
import Header from "components/Headers/Header";
import StatusTag from "components/StatusTag";
import Tabs from "components/Tabs";
import { calculateDaysAgo } from "helpers/common-helper";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import api from "services/api";

import CreateVoucherModal from "./CreateVoucherModal";
import styles from "./styles.module.scss";
import { isDatePassed } from "helpers/common-helper";

const VOUCHER_TYPES = ["Promotional", "Special", "Referral"];

const NewVoucher = () => {
  const [state, setState] = useState({
    tab: 0,
    isLoading: false,
    isEditMode: false,
    voucherStatus: "Active",
  });

  console.log({ state });

  const handleVoucherStatus = (status) => {
    setState((prev) => ({
      ...prev,
      voucherStatus: status,
    }));
  };

  const handleLoader = (loading) => {
    setState((prev) => ({
      ...prev,
      isLoading: loading ?? !prev.isLoading,
    }));
  };

  const handleTab = (tabId) => {
    setState((prev) => ({
      ...prev,
      tab: tabId,
    }));
  };

  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);
  const [voucherName, setVoucherName] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [minSpend, setMinSpend] = useState("");
  const [date, setDate] = useState("");
  const [dropdown, setuser] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allTutees, setAllTutees] = useState([]);
  const [publishTo, setPublishTo] = useState("");

  const [publishToList, setPublishToList] = useState([]);
  const [err, setErr] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [voucherMembers, setVoucherMembers] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState([]);
  const [selectedVoucherIndex, setSelectedVoucherIndex] = useState(0);
  const [newMemberList, setNewMemberList] = useState([]);
  const [selectedNewMemberList, setSelectedNewMemberList] = useState([]);
  const [splitVoucherModel, setSplitVoucherModel] = useState(0);
  const [splitVoucherValueOne, setSplitVoucherValueOne] = useState({});
  const [splitVoucherValueTwo, setSplitVoucherValueTwo] = useState({});
  const [splitVoucherButtonDisabled, setSplitVoucherButtonDisabled] =
    useState(true);
  const [isGlobal, setIsGlobal] = useState(false);

  async function getAllTutee() {
    try {
      const allUsers = await api.getAllUsers(`?userType=Tutee`);
      // if (!allusers.length)
      const filteredData = allUsers.data.map((user, id) => {
        let temp = user;
        temp = { ...temp, id, name: `${user.name} (${user.email})` };
        return temp;
      });

      setAllTutees(filteredData);
      // }
    } catch (e) {
      console.log(e?.response?.data?.message);
    }
  }

  async function getVouchers() {
    handleLoader(true);
    const queryParams = `?voucherType=${VOUCHER_TYPES[state.tab]}`;
    try {
      const vouchers = await api.getVouchers(queryParams);

      setVouchers(vouchers.data?.reverse());
      handleLoader(false);
    } catch (e) {
      console.log(e?.response?.data?.message);
      handleLoader(false);
    }
  }

  const postVoucher = async () => {
    if (voucherName || state.isEditMode) {
      if (shortDescription || state.isEditMode) {
        if (price || state.isEditMode) {
          if (minSpend || state.isEditMode) {
            if (date || state.isEditMode) {
              handleLoader(true);

              const data = {
                title: voucherName,
                expiryDate: date,
                price,
                minSpend,
                shortDescription,
                voucherType: "Promotional",
                // promStatus: "Active",
                promStatus: state.voucherStatus,
              };

              const updatePayload = {
                title: !isEmpty(voucherName)
                  ? voucherName
                  : selectedVoucher["title"],
                shortDescription: !isEmpty(shortDescription)
                  ? shortDescription
                  : selectedVoucher["shortDescription"],
                expiryDate: !isEmpty(date)
                  ? date
                  : selectedVoucher["expiryDate"],
                price: !isEmpty(price) ? price : selectedVoucher["price"],
                minSpend: !isEmpty(minSpend)
                  ? minSpend
                  : selectedVoucher["minSpend"],
                voucherType: "Promotional",
                promStatus: state.voucherStatus,
              };

              const apiToCall = state.isEditMode
                ? api.updateVoucher
                : api.postVoucher;

              await apiToCall(
                state.isEditMode ? updatePayload : data,
                selectedVoucher?._id
              );

              toast.success(
                `Voucher ${
                  state.isEditMode ? "updated" : "created"
                } successfully!`
              );
              //   setPublishTo([]);
              getVouchers();
              setOpen(false);
              setState((prev) => ({
                ...prev,
                isEditMode: false,
                isLoading: false,
                voucherStatus: "active",
              }));
            } else {
              setErr("Please select expiry date");
            }
          } else {
            setErr("Please enter min spend amount");
          }
        } else {
          setErr("Please enter price");
        }
      } else {
        setErr("Please enter short description");
      }
    } else {
      setErr("Please enter voucher title");
    }
  };

  const makeNewmemberList = () => {
    const newMemberList = [];
    allTutees.forEach((tutee) => {
      let flag = false;
      voucherMembers.forEach((element) => {
        if (element.user._id === tutee._id) {
          flag = true;
        }
      });
      if (!flag) {
        newMemberList.push(tutee);
      }
    });
    setNewMemberList(newMemberList);
  };

  const handleSplitVoucherButtonDisabled = () => {
    const totalAmount = Number(
      Number(splitVoucherValueOne) + Number(splitVoucherValueTwo)
    );
    if (totalAmount === Number(selectedVoucher?.price)) {
      setSplitVoucherButtonDisabled(false);
    } else if (totalAmount !== Number(selectedVoucher?.price)) {
      setSplitVoucherButtonDisabled(true);
    }
  };

  const handleDeleteVoucher = (selectedVoucher) => {
    api
      .deleteVoucher(selectedVoucher._id)
      .then((resp) => {
        console.log({ resp });
        toast.success("Voucher Deleted Successfully");
        getVouchers();
      })
      .catch((error) => {
        console.log({ error });
        toast.success("Unable to delete voucher.");
      });
  };

  useEffect(() => {
    handleSplitVoucherButtonDisabled();
  }, [splitVoucherValueOne, splitVoucherValueTwo]);

  useEffect(async () => {
    getAllTutee();
    getVouchers();
  }, [state.tab]);

  return (
    <div>
      <Header />

      <Container
        fluid
        style={{ paddingBottom: "20px" }}
        className={window.innerWidth <= 1366 ? "mt--7" : "mt--9"}
      >
        <Row
          style={{ display: "flex", justifyContent: "space-between" }}
          className={styles.voucherTabRow}
        >
          <Col
            xs={12}
            md={12}
            lg={12}
            xl={12}
            className={styles.topVoucherRowCol}
          >
            <div className={styles.voucherTitle}>Vouchers</div>

            <TabsRow handleTab={handleTab} />
          </Col>
        </Row>

        {state.tab === 0 && (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              margin: "0px 2px",
            }}
          >
            <Col
              xs={12}
              md={12}
              lg={5}
              xl={5}
              style={{
                minWidth: "49%",
                padding: 0,
                boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)",
              }}
            >
              <Card style={{ minHeight: "75vh" }} className="activtyLogShadow">
                <CardHeader className="border-0 voucher-card-head">
                  <h3 style={{ color: "#454545" }} className="mb-0">
                    Promotional Vouchers
                  </h3>
                  <Button
                    className="voucher-create-btn"
                    type="button"
                    onClick={() => setOpen(true)}
                  >
                    Create New
                  </Button>
                </CardHeader>

                <hr style={{ margin: "4px 0px" }} />

                <div style={{ maxHeight: "65vh", overflowY: "scroll" }}>
                  {state.isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"#ff6700"}
                        height={30}
                        width={30}
                      />
                    </div>
                  ) : (
                    vouchers?.map((voucher, index) => {
                      const isVoucherExpired = isDatePassed(voucher.expiryDate);
                      console.log({ isVoucherExpired });
                      return (
                        <div
                          key={index}
                          className="voucher-data"
                          style={{
                            border: `1px solid 
                            ${
                              selectedVoucher._id === voucher._id
                                ? "#FF8B3C"
                                : "#D3E0ED"
                            }`,
                            backgroundColor:
                              selectedVoucher._id === voucher._id
                                ? "#FFEDE1"
                                : "#F6F9FC",
                          }}
                        >
                          <div style={{ padding: "15px 18px" }}>
                            <div className={styles.titleContainer}>
                              <div
                                style={{
                                  margin: "5px 0",
                                  color: "#9A9A9A",
                                }}
                              >
                                <div className={styles.voucherName}>
                                  <img
                                    src="/voucher-icon.svg"
                                    alt="voucher-logo"
                                  />
                                  <h2 className={styles.voucherNameHeading}>
                                    <div
                                      title={voucher?.title}
                                      className={styles.heading}
                                    >
                                      {voucher?.title ?? "N/A"}
                                    </div>
                                    <div>
                                      <StatusTag
                                        type={
                                          isVoucherExpired
                                            ? "expired"
                                            : voucher.promStatus.toLowerCase()
                                        }
                                        title={
                                          isVoucherExpired
                                            ? "Expired"
                                            : voucher.promStatus
                                        }
                                      />
                                    </div>
                                  </h2>
                                </div>

                                <h5
                                  style={{
                                    color: "#9A9A9A",
                                    fontSize: "0.8vw",
                                    paddingTop: "10px",
                                  }}
                                >
                                  {`Published ` +
                                    calculateDaysAgo(voucher.createdAt)}
                                  {/* {convertDateFormat(voucher.expiryDate)} */}
                                </h5>
                              </div>
                              <div
                                style={{
                                  cursor: "pointer",
                                  // float: "right",
                                  color: "#FF6700",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setVoucherMembers(voucher.redeemedUsersList);
                                  setSelectedVoucher(voucher);
                                  setSelectedVoucherIndex(index);
                                }}
                              >
                                <img
                                  src="/voucher-expand-icon.svg"
                                  alt="exapnd icon"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                //   justifyContent: "space-between",
                                //   marginTop: "20px",
                                gap: "40px",
                                fontSize: "0.8vw",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Price:
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ${voucher.price}
                                </span>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Min. Spend:{" "}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {voucher?.minSpend}{" "}
                                </span>
                              </div>

                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Redeem Count:{" "}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {voucher.redeemedUsers?.length}{" "}
                                </span>
                              </div>

                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Expiry Date:{" "}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {moment(voucher?.expiryDate).format(
                                    "DD MMM, YYYY"
                                  )}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </Card>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={5}
              xl={5}
              style={{
                minWidth: "50%",
                padding: 0,
                boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)",
              }}
            >
              <Card style={{ height: "75vh" }} className="shadow">
                <CardHeader className="border-0 voucher-card-head1">
                  <div className={styles.actionDropdownBtn}>
                    <h3 className="mb-0">Redeem Details</h3>

                    {!isEmpty(selectedVoucher) && (
                      <div className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              //   href="#pablo"
                              onClick={() => {
                                setOpen(true);
                                setErr("");
                                setState((prev) => ({
                                  ...prev,
                                  isEditMode: true,
                                }));
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) =>
                                handleDeleteVoucher(selectedVoucher)
                              }
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        scope="col"
                      >
                        TUTEE
                      </th>
                      <th
                        style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        scope="col"
                      >
                        Redeemed Date
                      </th>
                      <th
                        style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        scope="col"
                      >
                        Invoice Id
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isEmpty(voucherMembers) ? (
                      <tr style={{ width: "100%" }}>
                        <td colSpan={3}>
                          <p style={{ fontSize: "14px", textAlign: "center" }}>
                            No Redeem Details Found
                          </p>
                        </td>
                      </tr>
                    ) : (
                      voucherMembers?.map((vm, i) => (
                        <tr key={i}>
                          <td style={{ maxWidth: "25%" }}>
                            <Media>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  textAlign: "left",
                                  // marginLeft: "10px",
                                }}
                              >
                                <Link
                                  className="mb-0 "
                                  style={{
                                    fontSize: "100%",
                                    fontWeight: 600,
                                  }}
                                  to={`/admin/Tutee/${vm.user?._id}`}
                                  // onClick={() => viewProfile(user.tutee[0], "tutee")}
                                >
                                  {vm.user?.name}
                                </Link>
                                <span
                                  style={{
                                    color: "#9a9a9a",
                                    fontSize: "85%",
                                  }}
                                >
                                  {vm.user?.memberId}
                                </span>
                              </div>
                            </Media>
                          </td>

                          <td>
                            {moment(vm?.redeemDate).format("DD MMM, YYYY")}
                          </td>
                          {/* <td>{String(vm.joinDate).substring(0, 10)}</td> */}
                          <td>
                            {vm?.invoiceId ? (
                              <div
                                //   className="tdTitle"
                                style={{ marginRight: "3px" }}
                              >
                                #{vm?.invoiceId?.substring(15)}{" "}
                              </div>
                            ) : (
                              "--"
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        )}

        {state.tab === 1 && (
          <VoucherTable isLoading={state.isLoading} data={vouchers} />
        )}

        {state.tab === 2 && (
          <VoucherTable
            data={vouchers}
            isTypeReferral={true}
            isLoading={state.isLoading}
            title="Referral Vouchers"
          />
        )}

        <CreateVoucherModal
          err={err}
          state={state}
          setState={setState}
          isOpen={isOpen}
          setOpen={setOpen}
          setPrice={setPrice}
          setDate={setDate}
          setShortDescription={setShortDescription}
          //   allTutees={allTutees}
          //   onSelect={onSelect}
          //   publishTo={publishTo}
          setMinSpend={setMinSpend}
          postVoucher={postVoucher}
          //   selectedTutee={selectedTutee}
          setVoucherName={setVoucherName}
          handleVoucherStatus={handleVoucherStatus}
          setSelectedVoucher={setSelectedVoucher}
          //   handleTuteeChange={handleTuteeChange}
          //   handleSelectChange={handleSelectChange}
          selectedVoucher={state.isEditMode ? selectedVoucher : {}}
        />
      </Container>
    </div>
  );
};

export default NewVoucher;

const TabsRow = ({ handleTab }) => {
  return (
    <TabsUnstyled defaultValue={0} className="unstyledTab">
      <Tabs
        tabsList={[
          "Promotional Vouchers",
          "Special Vouchers",
          "Referral Vouchers",
        ]}
        handleTab={handleTab}
      />
    </TabsUnstyled>
  );
};

const VoucherTable = ({
  isLoading,
  title = "Special Vouchers",
  isTypeReferral = false,
  data = [],
}) => {
  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
      }}
    >
      <Col>
        <Card
          style={{ minHeight: "700px", maxHeight: "700px" }}
          className="shadow"
        >
          <CardHeader className="border-0 voucher-card-head1">
            <div className={styles.actionDropdownBtn}>
              <h3 className="mb-0">{title}</h3>
            </div>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  TUTEE
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  {isTypeReferral ? `Invited By` : `Tuition`}
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Title
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Price
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Created At
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Status
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Redeem Date
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Invoice
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr
                  style={{
                    width: "100%",
                    height: "100%",
                    marginLeft: "10rem",
                  }}
                >
                  <td colSpan={9}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"#ff6700"}
                        height={30}
                        width={30}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((voucher) => (
                  <tr key={voucher?._id}>
                    <td>
                      <Media>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "left",
                          }}
                        >
                          <Link
                            className="mb-0 "
                            style={{
                              fontSize: "100%",
                              fontWeight: 600,
                            }}
                            to={`/admin/Tutee/${voucher.user[0]?._id}`}
                            // onClick={() => viewProfile(user.tutee[0], "tutee")}
                          >
                            {voucher.user[0]?.name}
                          </Link>
                          <span
                            style={{
                              color: "#9a9a9a",
                              fontSize: "85%",
                            }}
                          >
                            {voucher.user[0]?.memberId}
                          </span>
                        </div>
                      </Media>
                    </td>
                    <td>
                      {isTypeReferral ? (
                        isEmpty(voucher.invited_by_user) ? (
                          "--"
                        ) : (
                          <Media>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "left",
                              }}
                            >
                              <Link
                                className="mb-0 "
                                style={{
                                  fontSize: "100%",
                                  fontWeight: 600,
                                  color: "#5e72e4",
                                }}
                                to={`/admin/Tutee/${voucher?.invited_by_user[0]?._id}`}
                                // onClick={() => viewProfile(user.tutee[0], "tutee")}
                              >
                                {voucher.invited_by_user[0]?.name}
                              </Link>
                              <span
                                style={{
                                  color: "#9a9a9a",
                                  fontSize: "85%",
                                }}
                              >
                                {voucher.invited_by_user[0]?.memberId}
                              </span>
                            </div>
                          </Media>
                        )
                      ) : isEmpty(voucher.job) ? (
                        "--"
                      ) : (
                        <Media>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              textAlign: "left",
                            }}
                          >
                            <Link
                              className="mb-0 "
                              style={{
                                fontSize: "100%",
                                fontWeight: 600,
                                color: "#5e72e4",
                              }}
                              to={`/admin/TutionDetails/${voucher.job[0]?._id}`}
                              // onClick={() => viewProfile(user.tutee[0], "tutee")}
                            >
                              {voucher.job[0]?.course[0]?.courseName}
                            </Link>
                            <span
                              style={{
                                color: "#9a9a9a",
                                fontSize: "85%",
                              }}
                            >
                              {voucher.job[0]?.courseLevel}
                            </span>
                          </div>
                        </Media>
                      )}
                    </td>
                    <td>{voucher?.title ?? "N/A"}</td>
                    <td>{voucher?.price ? `$${voucher?.price}` : "N/A"}</td>
                    <td>{moment(voucher?.createdAt).format("DD MMM, YYYY")}</td>
                    <td>
                      <StatusTag
                        title={voucher?.redeemStatus}
                        type={voucher?.redeemStatus.toLowerCase()}
                      />
                    </td>
                    <td>
                      {moment(voucher?.redeemDate).format("DD MMM, YYYY")}
                    </td>

                    <td>
                      {/* {voucher?.invoiceId ?? "N/A"} */}
                      {voucher?.invoiceId ? (
                        <div style={{ marginRight: "3px" }}>
                          #{voucher?.invoiceId?.substring(15)}{" "}
                        </div>
                      ) : (
                        "--"
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};
