/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import { Container } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-5">
        {/* <Container>
          <div className="copyright text-muted">
            Copyright © 2023. Codingo Technologies Pvt Ltd. All Rights Reserved.
          </div>
        </Container> */}
      </footer>
    </>
  );
};

export default Login;
