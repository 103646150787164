import React from "react";
import styles from "./styles.module.css";
import { Media } from "reactstrap";
import { Avatar, Divider } from "@mui/material";
import { calculateAge } from "helpers/common-helper";

const ChildrenBlock = ({ user, children = [] }) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "0.625vw",
          padding: "20px 20px 0px 20px",
        }}
        className="card-profile shadow"
      >
        <h2
          style={{
            fontSize: "0.938vw",
            minHeight: "1.563vw",
            fontWeight: 600,
          }}
        >
          Added Children
        </h2>

        <Divider />
        <div className={styles.listContainer}>
          {children?.map((item) => (
            <div className={styles.userRow} key={item._id}>
              <Media className="align-items-center">
                {user?.photoUrl ? (
                  <img
                    className="rounded-circle mr-3"
                    style={{
                      width: "1.979vw",
                      height: "1.979vw",
                    }}
                    //   src={user.photoUrl}
                  />
                ) : (
                  <Avatar
                    className="mr-3"
                    style={{
                      width: "1.979vw",
                      height: "1.979vw",
                      fontSize: "1.042vw",
                      backgroundColor: "#CED4DA",
                    }}
                    alt={item.name}
                    src="/"
                  />
                )}

                <div className={styles.userBlock}>
                  <div
                    className="tdTitle"
                    style={{
                      color: "#5E72E4",
                    }}
                  >
                    {item.name}
                  </div>
                  <div className="postpaidTag">{`${calculateAge(
                    item.dateOfBirth
                  )} years old`}</div>
                </div>
              </Media>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChildrenBlock;
